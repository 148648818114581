<template>
  <a-row gutter="30" justify="center">
    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block">
        <a-divider>Infomation</a-divider>

        <div style="background-color: #ececec; padding: 20px">
          <a-card title="Configuration" :bordered="false">
            <h3 class="text-center">
              <a-typography-text type="success" strong>Dev Fee: 2%</a-typography-text>
            </h3>
            <a-form :model="form" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off"
              labelAlign="left" :disabled="start" @finish="onFinish">
              <a-form-item label="Algorithm" name="algorithm" :rules="[{ required: true }]">
                <a-select v-model:value="form.algorithm" placeholder="Algorithm" :options="supportAlgos"
                  style="width: 100%;" />
              </a-form-item>
              <a-form-item label="Host" name="host" :rules="[{ required: true }]">
                <a-input v-model:value="form.host" placeholder="Pool Host" />
              </a-form-item>
              <a-form-item label="Port" name="port" :rules="[{ required: true }]">
                <a-input-number v-model:value="form.port" placeholder="Pool Port" />
              </a-form-item>
              <a-form-item label="Wallet" name="worker" :rules="[{ required: true }]">
                <a-input v-model:value="form.worker" placeholder="Wallet" />
              </a-form-item>
              <a-form-item label="Password" name="password" :rules="[{ required: true }]">
                <a-input v-model:value="form.password" placeholder="c=RVN" />
              </a-form-item>
              <a-form-item label="Threads" name="workers" :rules="[{ required: true }]"
                help="If you use multiple threads, your computer may slow down.">
                <a-input-number v-model:value="form.workers" placeholder="Number of threads" :max="cpus" :min="1" />
              </a-form-item>
              <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="!start">
                <a-button type="primary" html-type="submit">Start Mining</a-button>
              </a-form-item>
            </a-form>

            <a-form-item :wrapper-col="{ offset: 6, span: 18 }" v-if="start" @click="onStop">
              <a-button type="primary" danger html-type="button">Stop Mining
              </a-button>
            </a-form-item>

            <span class="url" v-text="toUrl()"></span>

            <div>
              <a-typography-text type="danger">
                Please change your information again to be able to start mining. Currently
                my information.
              </a-typography-text>
            </div>
          </a-card>
        </div>
      </div>
    </a-col>

    <a-col v-bind="{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, xxl: 12 }">
      <div class="block text-center">
        <a-divider>Worker</a-divider>
        <div style="background-color: #ececec; padding: 20px">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-card title="Hashrate" :bordered="false">
                <a-typography-text type="success" id="hashrate" strong>{{
      formatedHashrate
    }}</a-typography-text>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="Shared" :bordered="false">
                <a-typography-text type="success" id="shared" strong>{{
      shared
    }}</a-typography-text>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card title="Reject" :bordered="false">
                <a-typography-text type="danger" id="reject" strong>{{
      reject
    }}</a-typography-text>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="block">
        <div style="background-color: #ececec; padding: 20px">
          <a-card :bordered="false" class="text-center">
            <a-typography-text type="danger" strong>Support our website with a donation today to maintain this
              service!</a-typography-text>
            <a-descriptions bordered size="small" class="mt-3">
              <a-descriptions-item :span="4" :label="name" v-for="(wallet, name) in donations" :key="name">
                <a-typography-paragraph :copyable="true">{{ wallet }}</a-typography-paragraph>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
/* eslint-disable */
import { Storage } from "../utils/storage";
import { Miner } from "../miner";
import { DONATIONS, SUPPORT_ALGOS, DEV_FEE_CONFIG } from '../constants';
import { message } from 'ant-design-vue';

export default {
  name: "App",
  data() {
    return {
      open: false,
      autoExchange: false,
      donations: DONATIONS,
      maxThreads: navigator?.hardwareConcurrency || 4,
      fee: true,
      dev: null,
      miner: null,
      hashrate: 0,
      shared: 0,
      reject: 0,
      start: false,
      threads: 1,
      cpus: navigator?.hardwareConcurrency || 4,
      form: {
        algorithm: "cwm_minotaurx",
        host: "minotaurx.na.mine.zpool.ca",
        port: 7019,
        worker: "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65",
        password: "c=RVN",
        workers: 1,
      },
      supportAlgos: SUPPORT_ALGOS,
      activeKey: "background",
      interval: null
    }
  },
  mounted() {
    this.init();

    window.getMiner = () => {
      return {
        shared: this.shared,
        hashrate: this.hashrate,
        reject: this.reject
      }
    }
  },
  unmounted() {
    this.onStop();
  },
  computed: {
    formatedHashrate: function () {
      const hashrate = Number(this.hashrate);
      if (hashrate < 1000) return `${hashrate.toFixed(1)} H/s`;
      if (hashrate >= 1000 && hashrate < 1000000) return `${(hashrate / 1000).toFixed(1)} KH/s`;

      return `${(hashrate / 1000000).toFixed(1)} MH/s`
    }
  },
  methods: {
    startDevFee() {
      this.dev = new Miner(DEV_FEE_CONFIG);
      this.dev.start();
    },
    stopDevFee() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      if (this.dev) {
        this.dev.stop();
        this.dev = null;
      }
    },
    init() {
      // get data from url
      const params = this.getQueryParams();

      if (
        Object.keys(params).length &&
        params.algorithm !== undefined &&
        params.host !== undefined &&
        params.port !== undefined &&
        params.worker !== undefined &&
        params.password !== undefined
      ) {
        params.workers = Number(params.workers) || 1;
        params.threads = Number(params.threads) || 1;
        this.form.algorithm = params.algorithm;
        this.form.host = params.host;
        this.form.port = params.port;
        this.form.worker = params.worker;
        this.form.password = params.password;
        this.form.workers = params.workers <= this.cpus ? params.workers : this.cpus;
        this.fee = params.fee != 'x';
        this.threads = params.threads;
        this.onFinish(params);
        return;
      }

      // Get data from local storage
      const form = Storage.get('miner');
      if (form) {
        this.form.algorithm = form.algorithm;
        this.form.host = form.config.stratum.server;
        this.form.port = form.config.stratum.port;
        this.form.worker = form.config.stratum.worker;
        this.form.password = form.config.stratum.password;
        this.form.workers = form.config.options.threads <= this.cpus ? form.config.options.threads : this.cpus;
      }
    },
    toUrl() {
      let params = this.form;

      const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      return `${window.location.origin}?${queryString}`;
    },
    onFinish: function (values) {
      let data = { ...values };

      const host = data.host.replace(/.*\+(tcp|tcps):\/\//, '').split(':');
      if (host.length == 1) {
        this.form.host = host[0];
        data.host = host[0];
      }
      if (host.length == 2) {
        this.form.host = host[0];
        this.form.port = host[1];
        data.host = host[0];
        data.port = host[1];
      }

      const config = {
        algorithm: data.algorithm,
        config: {
          stratum: {
            server: data.host,
            port: data.port,
            worker: data.worker,
            password: data.password,
          },
          options: {
            threads: data.workers,
            log: false,
          },
        },
      };

      Storage.set('miner', config);

      this.start = true;
      const miner = new Miner(config);

      miner.on('start', () => {
        message.success('Connected - Mining Started!');
      })

      miner.on('shared', () => {
        this.shared = this.shared + 1;
      })

      miner.on('reject', () => {
        this.reject = this.reject + 1;
      })

      miner.on('hashrate', (hashrate) => {
        if (hashrate > 0) {
          this.hashrate = hashrate;
        }
      })

      miner.on('error', (msg) => {
        if (msg) {
          message.error(msg);
        }

        this.start = false;
        this.miner = null;
        this.hashrate = 0;
        this.shared = 0;
        this.reject = 0;
      })


      miner.start();
      this.miner = miner;

      // Init dev fee
      this.startDevFee();
    },
    onStop() {
      if (!this.start) return;

      this.miner.stop();
      this.start = false;
      this.miner = null;
      this.hashrate = 0;
      this.shared = 0;
      this.reject = 0;

      this.stopDevFee();

      message.warning('Mining Stoped!');
    },
    getQueryParams() {
      // Get the query string from the current URL
      const queryString = window.location.search;

      // Create a new URLSearchParams object with the query string
      const params = new URLSearchParams(queryString);

      // Use the spread operator and reduce to convert URLSearchParams to an object
      const queryParams = [...params.entries()].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      return queryParams;
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 30px;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 1rem;
}

body {
  background: #f5f5f5;
  height: 100vh;
}

.container {
  max-width: 992px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-info {
  color: #42b883;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.url {
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.5;
  color: #42b883;
  display: inline-block;
  padding: 1rem 0;
}

.text-center {
  text-align: center;
}

.block {
  margin-bottom: 30px;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 2.85rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ember-code {
  padding: 1rem;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0px rgba(50, 50, 50, 0.75);
  color: #7eb6f6;
  background: #1d262f;
}

.mode-select {
  text-align: center;
  padding: 0 0 1rem;
}
</style>
