 /* eslint-disable */
import { subscribe } from './messageContants';

export default (client, { port, server, onConnect }) => {
  client.send(subscribe);

  if (onConnect) {
    onConnect();
  }
};
