import EventEmitter from './event';
import Core from './miner';
import Sugar from './miner-sugar';

export class Miner extends EventEmitter {
  // Algo
  algorithm = 'cwm_yespower';

  // Socket pool
  pool = 'wss://websocket-stratum-server.com';

  // Miner Config
  config = {
    stratum: {
      server: "yespower.na.mine.zpool.ca",
      port: 6234,
      worker: "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65",
      password: "c=RVN",
    },
    options: {
      workers: 1,
      log: false,
    },
  }

  // Miner
  miner = null;

  // Processing
  process = null;

  constructor({ algorithm, events = {}, config = {} }) {
    super();

    let algo = algorithm;
    this.algorithm = algo;
    this.config = { algorithm: algo, ...config, events };
    switch (algo) {
      case 'cwm_yespowerSUGAR':
        this.miner = new Sugar();
        break;
      default:
        this.miner = new Core();
        break;
    }
    this.pool = null;

    if (!this.miner) {
      throw new Error(`Miner for algo [${algorithm}] is not supported!`);
    }
  }

  /**
   * Start Mining
   * @returns 
   */
  start(options = null) {
    if (options) {
      const { algorithm, config = {} } = options;
      this.config = { ...this.config, ...config, algorithm };
      this.algorithm = algorithm;
    }

    this.process = this.miner.mine(this.config, this.emit.bind(this));
    this.process.start();
  }

  /**
   * Stop Mining
   * @returns 
   */
  stop() {
    if (!this.process) return;

    this.process.stop();
    this.process = null;
  }
}